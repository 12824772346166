<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <h1 v-if="$route.params.id == null">Добавление ситуации</h1>
          <h1 v-else>Редактирование ситуации</h1>

          <v-row class="mb-24">
            <v-col cols="4">
              <v-text-field placeholder="Название ситуации" v-model="situation.situationName"></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mb-48">
            <v-col cols="12">
              <label>Тип ситуации</label>
              <v-radio-group
                  v-model="situation.situationType"
                  row
              >
                <v-radio
                    label="Жизненная ситация"
                    value="LIVE_SITUATION"
                ></v-radio>
                <v-radio
                    label="Бизнес-ситуация"
                    value="BUSINESS_SITUATION"
                ></v-radio>
              </v-radio-group>

            </v-col>
          </v-row>


          <h2>Перечень услуг</h2>

          <table class="config-list-table no-controls">
            <tr v-for="(service, serviceIndex) in servicesAvailable" :key="'trs'+serviceIndex">
              <td>
                <v-checkbox
                    :input-value="isServiceIncluded(service.id)"
                    @change="toggleService($event, service.id)"
                ></v-checkbox>
              </td>
              <td>
                {{service.serviceName}}
              </td>
            </tr>
            <tr v-for="(customService, customServiceIndex) in situation.customServices" :key="'trcs'+customServiceIndex">
              <td>
                <v-checkbox input-value="true" @change="deleteCustomService(customServiceIndex)"></v-checkbox>
              </td>
              <td>
                <v-textarea v-model="customService.serviceName"></v-textarea>
              </td>
            </tr>
          </table>

          <a class="configAdd" href="#"  @click.prevent="addCustomService">+ Добавить услугу</a>


          <div class="save-buttons">
            <v-btn outlined @click="$router.push('/config/situations/')">отмена</v-btn>
            <v-btn color="primary" @click="submit" :disabled="submitButtonDisabled">Сохранить</v-btn>
          </div>


        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>

</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

export default {
  name: "SituationsConfigAdd",
  props: {},
  components: {},
  data() {
    return {
      errorText: null,
      apiLoaded: false,
      servicesAvailable: [],
      submitButtonDisabled: false,
      situation: {
        id: null,
        situationType: 'LIVE_SITUATION',
        situationName: null,
        serviceDict: [],
        customServices: []}
    };
  },
  methods: {
    toggleService(enabled, serviceId){
      if(enabled){
        this.addService(serviceId)
      } else {
        this.removeService(serviceId)
      }
    },

    addService(serviceId){
      this.situation.serviceDict.push({id: serviceId})
    },

    removeService(serviceId){
      let list = this.situation.serviceDict
      for(let i = 0; i < list.length; i++){
        if(list[i].id === serviceId)
          list.splice(i, 1)
      }
    },

    addCustomService(){
      this.situation.customServices.push({id: null, serviceName: ""});
      this.$forceUpdate()
    },

    deleteCustomService(index){
      this.situation.customServices.splice(index, 1)
      this.$forceUpdate()
    },

    async loadServicesAvailable(){
      let req = await this.$getApi("/situations/getAvailableServices")
      if (req.ok) {
        this.servicesAvailable = req.payload
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    async loadSituation(situationId){
      let req = await this.$getApi("/situations/getSituationsDict")
      if (req.ok) {
        let situationsList = req.payload
        this.situation = this.extractSituation(situationsList, situationId)
        this.situation.customServices = []
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    extractSituation(situationsList, situationId){
      for(let situation of situationsList)
        if(situation.id === +situationId)
          return situation
    },

    isServiceIncluded(serviceId){
      for(let service of this.situation.serviceDict){
        if(service.id === +serviceId)
          return true
      }
      return false
    },

    validate(){
      if(this.situation.situationName == null || this.situation.situationName.length === 0)
        return "Укажите название ситуации"
      for(let s of this.situation.customServices){
        if(s.serviceName == null || s.serviceName.length === 0)
          return "Название услуги не может быть пустым"
      }
      return true
    },

    async submit(){
      let valstat = this.validate()
      if(valstat !== true){
        this.errorText = valstat
        return
      }
      this.situation.serviceDict = this.situation.serviceDict.concat(this.situation.customServices)

      this.submitButtonDisabled = true
      let req = await this.$postApi("/situations/saveSituationDict", this.situation)
      if (req.ok) {
        this.$router.push("/config/situations")
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
        this.submitButtonDisabled = false
      }
    }
  },
  async beforeMount() {
    let promise1 = this.loadServicesAvailable()
    if (this.$route.params.id != null) {
      //режим редактирования
      await this.loadSituation(this.$route.params.id)
    }
    await promise1
    this.apiLoaded = true
  }
};
</script>
